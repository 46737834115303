<template>
  <v-nav v-if="this.$route.name !== 'SocialLinks'"/>
  <router-view/>
  <footer>
    <div class="social-icons">
      <a href="https://www.instagram.com/yungwu.art/" rel="noopener noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z"/></svg></a>
      <a href="mailto:yungwu.art@gmail.com" rel="noopener noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="m0 12 11 3.1L18 7l-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339L11 17.946v-.769l-2-.56V24l4.473-6.031L18 22l6-22z"/></svg></a>
    </div>
    <router-link to="/subscribe" class="button-link">Join Mailing List</router-link>
  </footer>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  components: {
    'v-nav': NavBar
  }
}
</script>

<style lang="scss">
@import '@/styles/base.scss';

footer {
  // outline: 1px solid blue;
  align-items: center;
  border-top: .5px solid $color-shade-900;
  bottom: 0;
  display: flex;
  height: 64px;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 16px);

  @include min-width(medium) {
    width: calc(100% - 80px);
  }

  .social-icons {
    margin: $space-40 0;

    a {
      margin: 0 0 0 8px;
    }
  }

  .button-link {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
}
</style>
