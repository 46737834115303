import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

  const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'AboutMe',
    component: () => import('../views/AboutMe.vue')
  },
  {
    path: '/subscribe',
    name: 'MailingList',
    props: true,
    component: () => import('../views/MailingList.vue')
  },
  {
    path: '/contact',
    name: 'ContactForm',
    props: true,
    component: () => import('../views/ContactForm.vue')
  },
  {
    path: '/works/:id',
    name: 'WorkItem',
    component: () => import('../views/WorkItem.vue')
  },
  {
    path: '/works',
    name: 'WorksAll',
    component: () => import('../views/WorksAll.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
      document.getElementById('app').scrollIntoView()
    }
  },
  routes
})

export default router
