<template>
  <div id="home" class="page">
    <v-lazy-img :src="'https://ik.imagekit.io/9sjnvniyw/' + newestWork + '.webp'"/>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  computed: {
    newestWork() {
      return this.$store.state.works[0].url
    }
  }
}
</script>

<style lang="scss" scoped>
#home {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: $space-20;

  @include min-width(wide) {
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;

    @include min-width(wide) {
      margin: $space-20 $space-40;
      max-width: 700px;
    }
  }
}
</style>